import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import ReportComparisonTable from "./report_comparison_table";
import ReportCommonlyCitedLinksTable from "./report_commonly_cited_links_table";
import { getReportById, getClusteredQuestions } from "./redux/selectors";
import {
  fetchReportIfNeeded,
  fetchReport,
  ignoreTopic,
  restoreTopic,
  toggleHighlightedTopic,
  addHighlightedTopics,
  removeHighlightedTopics,
  toggleHighlightedCitations,
  toggleHighlightedStatistic,
  showToast,
  deleteTopic,
  requestContentRevisions,
  toggleCard,
} from "./redux/actions";
import {
  gradeToColor,
  wordCountToColor,
  dateToPrettyString,
  formattedNumber,
  downloadCSV,
  isImportedAndNotConfirmed,
} from "./helpers";
import Loader from "./loader";
import ReportOverviewQuestionsOld from "./report_overview_questions_old";
import ReportOverviewQuestions from "./report_overview_questions";
import ReportOverviewOutlines from "./report_overview_outlines";
import ReportStatisticsTable from "./report_statistics_table";
import OutlineBuilder from "./outline_builder";
import ReportOverviewTopics from "./report_overview_topics";
import TextLoader from "./loader_text";

class ReportOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      topicsExpanded: false,
      competitorsExpanded: false,
      isAnon: false, // used to lock down the anonymous brief
      outlineBuilderMinimized: false,
      groupBySemanticSimilarity: false, // used to group topics by semantic similiarity
      asyncComponentStatus: {
        questions: "Pending",
        external_citations: "Pending",
      },
      siteMetrics: {
        isLoading: false,
        domainAuthority: null,
      },
    };
    this.downloadCompetitors = this.downloadCompetitors.bind(this);
    this.toggleCard = this.toggleCard.bind(this);
    this.toggleHighlightedTopic = this.toggleHighlightedTopic.bind(this);
    this.toggleHighlightedStatistic =
      this.toggleHighlightedStatistic.bind(this);
    this.toggleHighlightedCitations =
      this.toggleHighlightedCitations.bind(this);
    this.handleOutlineBuilderMinimize =
      this.handleOutlineBuilderMinimize.bind(this);
    this.handleOutlineBuilderExpand =
      this.handleOutlineBuilderExpand.bind(this);
    this.setGroupBySemanticSimiliarity =
      this.setGroupBySemanticSimiliarity.bind(this);
    this.startAsyncPollIfNeeded = this.startAsyncPollIfNeeded.bind(this);
    this.refreshAsyncComponents = this.refreshAsyncComponents.bind(this);
    this.fetchDomainAuthority = this.fetchDomainAuthority.bind(this);
    this.fetchCachedDomainAuthority = this.fetchCachedDomainAuthority.bind(this);
    this.reportOverviewRef = React.createRef();

    this.outlineBuilderMinScreenWidth = 800; // the minimum width of the window to show the outline builder
  }

  componentDidMount() {
    this.props.fetchReportIfNeeded(this.props.match.params.id);
    this.props.requestContentRevisions(this.props.match.params.id); // For Receiving Revisions for the Report.
    this.fetchDomainAuthority();
    $(this.reportOverviewRef.current).find('[data-toggle="tooltip"]').tooltip();
  }

  componentDidUpdate(prevProps) {
    $(".tooltip").remove();
    $(this.reportOverviewRef.current).find('[data-toggle="tooltip"]').tooltip();

    if (this.props.report && this.props.report.report) {
      const {
        related_questions,
        related_questions_quora,
        related_questions_autocomplete,
        external_links_analysis,
      } = this.props.report.report;
      // Start polling for questions if they are not present in the report
      if (
        typeof related_questions !== "undefined" &&
        typeof related_questions_quora !== "undefined" &&
        typeof related_questions_autocomplete !== "undefined"
      ) {
        if (
          this.state.asyncComponentStatus &&
          this.state.asyncComponentStatus.questions !== "Success"
        ) {
          this.setState((prevState) => ({
            asyncComponentStatus: {
              ...prevState.asyncComponentStatus,
              questions: "Success",
            },
          }));
        }
      } else {
        this.startAsyncPollIfNeeded("questions");
      }

      // Start polling for citations if they are not present in the report
      if (typeof external_links_analysis !== "undefined") {
        if (
          this.state.asyncComponentStatus &&
          this.state.asyncComponentStatus.external_citations !== "Success"
        ) {
          this.setState((prevState) => ({
            asyncComponentStatus: {
              ...prevState.asyncComponentStatus,
              external_citations: "Success",
            },
          }));
        }
      } else {
        this.startAsyncPollIfNeeded("external_citations");
      }
    }
  }

  componentWillUnmount() {
    if (_.isObject(this.asyncStatusPollInterval)) {
      _.forEach(this.asyncStatusPollInterval, (value, key) => {
        window.clearInterval(value);
      });
    }
  }

  static getDerivedStateFromProps(nextProps, state) {
    if (nextProps.report && !_.isEmpty(nextProps.report.anon_email)) {
      const derivedState = {
        isAnon: true,
      };
      return derivedState;
    }
    return null;
  }

  downloadCompetitors() {
    if (_.isArray(this.props.report.report.competitors)) {
      const data = [
        [
          "position",
          "url",
          "title",
          "updated",
          "authority",
          "word count",
          "content grade",
          "article",
        ],
      ];
      _.each(this.props.report.report.competitors, (competitor) => {
        data.push([
          competitor.position,
          competitor.url.replace(/,/g, ""),
          competitor.title.replace(/,/g, ""),
          dateToPrettyString(new Date(competitor.date)).replace(/,/g, ""),
          competitor.domain_score,
          competitor.word_count,
          competitor.grade,
          competitor.type === "article" ? "true" : "false",
        ]);
      });
      downloadCSV(
        `Competitors for ${this.props.report.report.focus_keyword}.csv`,
        data
      );
    }
  }

  toggleCard(card) {
    this.props.toggleCard(this.props.report, card);
  }

  toggleHighlightedTopic(topic) {
    this.props.toggleHighlightedTopic(this.props.report, topic);
  }

  toggleHighlightedStatistic(statistics) {
    this.props.toggleHighlightedStatistic(this.props.report, statistics);
  }

  toggleHighlightedCitations(citation) {
    this.props.toggleHighlightedCitations(this.props.report, citation);
  }

  handleOutlineBuilderMinimize() {
    this.setState({ outlineBuilderMinimized: true });
  }

  handleOutlineBuilderExpand() {
    if ($(window).width() >= this.outlineBuilderMinScreenWidth) {
      this.setState({ outlineBuilderMinimized: false });
    } else {
      this.props.showToast({
        message: `Expand the window to enable the outline builder. Minimum browser width supported: ${this.outlineBuilderMinScreenWidth}px`,
        options: { appearance: "info", autoDismiss: true },
      });
    }
  }

  setGroupBySemanticSimiliarity(shouldGroup) {
    this.setState({
      groupBySemanticSimilarity: shouldGroup,
    });
  }

  startAsyncPollIfNeeded(async_type) {
    try {
      const pollInterval = 7000;
      let that = this;
      let isRetry = false;
      if (!_.isObject(this.asyncStatusPollInterval)) {
        this.asyncStatusPollInterval = {};
      }
      if (
        this.asyncStatusPollInterval &&
        this.asyncStatusPollInterval[async_type]
      ) {
        window.clearInterval(this.asyncStatusPollInterval[async_type]);
      }
      this.asyncStatusPollInterval[async_type] = window.setInterval(() => {
        if (
          this.state.asyncComponentStatus &&
          this.state.asyncComponentStatus[async_type] != "Pending"
        ) {
          if (this.state.asyncComponentStatus[async_type] == "Retrying") {
            isRetry = true;
            this.setState((prevState) => ({
              asyncComponentStatus: {
                ...prevState.asyncComponentStatus,
                [async_type]: "Pending",
              },
            }));
          } else {
            window.clearInterval(this.asyncStatusPollInterval[async_type]);
          }
        }
        fetch(`/api/keyword_reports/status/${async_type}/${AP.report.id}`, {
          credentials: "same-origin",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status === "Success") {
              that.setState((prevState) => ({
                asyncComponentStatus: {
                  ...prevState.asyncComponentStatus,
                  [async_type]: response.status,
                },
              }));
              that.props.fetchReport(AP.report.id);
            } else if (response.status === "Error") {
              if (!isRetry) {
                that.setState((prevState) => ({
                  asyncComponentStatus: {
                    ...prevState.asyncComponentStatus,
                    [async_type]: response.status,
                  },
                }));
              }
            }
          });
      }, pollInterval);
    } catch (e) {
      AP.reportError(e);
    }
  }

  refreshAsyncComponents(async_type) {
    if (this.state.asyncComponentStatus[async_type] == "Error") {
      this.setState(
        (prevState) => ({
          asyncComponentStatus: {
            ...prevState.asyncComponentStatus,
            [async_type]: "Retrying",
          },
        }),
        () => {
          this.startAsyncPollIfNeeded(async_type);
        }
      );
      fetch(`/api/keyword_reports/refresh/${async_type}/${AP.report.id}`, {
        credentials: "same-origin",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    }
  }

  fetchDomainAuthority(){
    const siteId = AP?.cafemediaSite?.id;
    const siteURL = AP?.cafemediaSite?.url;
    const domainAuthority = this.fetchCachedDomainAuthority(siteURL);
    if(siteId){
      if(domainAuthority){
        this.setState({siteMetrics: {isLoading: false, domainAuthority}});
      }else{
        this.setState({siteMetrics: {isLoading: true, domainAuthority: null}},()=>{
          fetch(`/api/domain_authority/${siteId}`, {
            credentials: "same-origin",
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.status === "Success") {
                const {domain_authority} = response;
                if(domain_authority){
                  AP.domainAuthority = domain_authority;
                  this.setState({siteMetrics: {isLoading: false, domainAuthority: domain_authority}});
                }
              }else{
                this.setState({siteMetrics: {isLoading: false, domainAuthority: null}});
              }
            });
        });
      }
    }
  }

  fetchCachedDomainAuthority(siteURL){
      let domainAuthority = AP?.domainAuthority;
      try{
        // THIS IS A TEMP FIX (REMOVE THIS AFTER THE DOMAIN AUTHORITY SYNC IS FIXED)
        const competitors = this.props?.report?.report?.competitors;
        if(competitors && competitors.length > 0){
          const competitor = competitors.find((c)=>{
            const competitorDomain = new URL(c.url).hostname;
            const siteDomain = new URL(siteURL).hostname;
            return competitorDomain === siteDomain; 
          });
          if(competitor && competitor.domain_score && competitor.domain_score > 0){
            domainAuthority = competitor.domain_score;
          }
        }
        return domainAuthority;
      }catch(e){
        return domainAuthority;
      }
  }

  render() {
    const that = this;
    AP.report = this.props.report;
    const {
      isAnon,
      outlineBuilderMinimized,
      groupBySemanticSimilarity,
      asyncComponentStatus,
      siteMetrics
    } = this.state;
    const isLoading =
      _.isEmpty(this.props.report) || _.isEmpty(this.props.report.report);
    const isPending =
      !isLoading &&
      (_.isEmpty(this.props.report) ||
        _.isEmpty(this.props.report.report) ||
        _.isEmpty(this.props.report.report.recommended_topics));
    const userIsCafemedia =
      (AP &&
        AP.currentBriefUser &&
        AP.currentBriefUser.plan &&
        AP.currentBriefUser.plan.meta &&
        AP.currentBriefUser.plan.meta.cafemedia) ||
      (AP &&
        AP.currentUser &&
        AP.currentUser.plan &&
        AP.currentUser.plan.meta &&
        AP.currentUser.plan.meta.cafemedia);
    const isImportedAndNotConfirmedState = isImportedAndNotConfirmed(
      this.props.report
    );
    const isEditor = /\/editor/.test(window.location.href);
    let questionsView = asyncComponentStatus.questions;
    let citationsView = asyncComponentStatus.external_citations;
    const cafemediaSiteURL = AP?.cafemediaSite?.url;
    if (!isLoading && !isPending) {
      var {
        related_questions,
        related_questions_quora,
        related_questions_autocomplete,
        competitors,
        external_links_analysis,
      } = this.props.report.report;
      var editorIsEmpty =
        _.isEmpty(this.props.report.content) ||
        this.props.report.content.html.trim() === "";

      var searchIntentPercent;
      if (
        _.isArray(competitors) &&
        _.isObject(competitors[0]) &&
        competitors[0].type
      ) {
        const firstPageCompetitors = _.filter(
          competitors,
          competitor => competitor.position <= 10
        );
        
        searchIntentPercent = Math.round(
          (_.filter(
            firstPageCompetitors,
            (competitor) => competitor.type && competitor.type === "article"
          ).length /
            firstPageCompetitors.length) *
            100
        );
      }
      if (
        typeof related_questions !== "undefined" &&
        typeof related_questions_quora !== "undefined" &&
        typeof related_questions_autocomplete !== "undefined"
      ) {
        var isNewQuestionView =
          related_questions.questions &&
          !_.isString(related_questions.questions.query) &&
          !_.isUndefined(related_questions_quora) &&
          !_.isUndefined(related_questions_autocomplete);
      }

      if (
        typeof related_questions !== "undefined" &&
        typeof related_questions_quora !== "undefined" &&
        typeof related_questions_autocomplete !== "undefined"
      ) {
        questionsView = "Success";
      }

      if (typeof external_links_analysis !== "undefined") {
        citationsView = "Success";
      }
    }

    let researchWidth;
    if (outlineBuilderMinimized) {
      researchWidth = "100%";
    } else if ($(window).width() > 1100) {
      researchWidth = "65vw";
    } else {
      researchWidth = "60vw";
    }

    return (
      <div
        className="pr-4 pl-4 w-100 d-flex"
        style={{ height: "calc(100vh - 58px)", overflowY: "scroll" }}
      >
        <div
          className="report-overview-research-holder"
          style={{ width: researchWidth }}
        >
          <div className="report-overview" ref={this.reportOverviewRef}>
            {isLoading && (
              <div className="p-5 w-100 text-center">
                <Loader type="spinner" className="dark" />
              </div>
            )}
            {isPending && (
              <div className="p-5 w-100 text-center">
                <p>
                  It looks like your brief is still being created. Come back in
                  a couple of minutes.
                </p>
                <p className="mt-3 text-s">
                  If it's been more than a couple of minutes and your report
                  still hasn't been created, please email{" "}
                  <a
                    href="mailto:support@usetopic.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    support@usetopic.com
                  </a>
                </p>
              </div>
            )}
            {!isLoading && !isPending && (
              <div className="report-overview-row mt-4">
                {this.props.report.report.opted_out && (
                  <div className="p-2 element-box w-100 h-100">
                    <div className="d-flex bd-highlight">
                      <div className="p-2 w-100 bd-highlight d-flex align-items-center">
                        <i
                          className="fa fa-warning text-gray text-xl ml-2 mr-2"
                          style={{ fontSize: "16px" }}
                        />{" "}
                        Your site is currently opted-out of showing up in
                        Content Research for other Topic users.{" "}
                        <div className="badge bg-blue-2 text-blue-8 text-s ml-2 mr-2 p-1">
                          beta
                        </div>
                      </div>
                      <div className="flex-shrink-1 bd-highlight p-2">
                        <a
                          className="btn btn-grey mr-2 text-bold p-2"
                          href="mailto:support@adthrive.com?subject=Opt-In%20to%20Topic%20Content%20Research&body=Hi%20AdThrive%20Support%2C%0D%0A%0D%0AI'd%20like%20to%20opt%20back%20into%20the%20Content%20Research%20feature%20of%20Topic.%0D%0A%0D%0AThanks!"
                        >
                          Opt Back In
                        </a>
                      </div>
                    </div>
                  </div>
                )}
                <div className="element-box pt-4 pb-4 w-100 h-100">
                  <div className="d-flex flex-row">
                    <div className="d-flex flex-grow-1 report-overview-metric-container flex-basis-0">
                      <div className="pr-4 pt-3">
                        {editorIsEmpty && (
                          <div>
                            <div className="report-overview-metric-title">
                              Recommended Word Count
                            </div>
                            <div className="text-xxl text-bold report-overview-card-metric">
                              {this.props.report.report.recommended_word_count}
                            </div>
                          </div>
                        )}
                        {!editorIsEmpty && (
                          <div>
                            <div className="report-overview-metric-title">
                              Your Word Count
                            </div>
                            <div
                              className={`text-xxl text-bold report-overview-card-metric border-${wordCountToColor(
                                this.props.report.content.word_count,
                                this.props.report.report.recommended_word_count
                              )}`}
                              style={{ borderBottom: `3px solid` }}
                            >
                              {this.props.report.content.word_count}
                            </div>
                            <div>
                              Suggested: at least{" "}
                              {this.props.report.report.recommended_word_count}
                            </div>
                          </div>
                        )}
                        <div className="mt-1 text-muted text-s">
                          If there is not enough content, readers will not stay
                          on the page as long as competitor content.
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 report-overview-metric-container flex-basis-0">
                      <div className="pr-4 pt-3">
                        {!isImportedAndNotConfirmedState && editorIsEmpty && (
                          <div>
                            <div className="report-overview-metric-title">
                              Recommended Content Grade
                            </div>
                            <div className="text-xxl text-bold report-overview-card-metric">
                              {this.props.report.report.recommended_grade}
                            </div>
                          </div>
                        )}
                        {!isImportedAndNotConfirmedState && !editorIsEmpty && (
                          <div>
                            <div className="report-overview-metric-title">
                              Your Content Grade
                            </div>
                            <div
                              className="text-xxl text-bold report-overview-card-metric"
                              style={{ borderBottom: `3px solid` }}
                            >
                              {this.props.report.content.grade}
                            </div>
                            <div>
                              Suggested: at least{" "}
                              {this.props.report.report.recommended_grade}
                            </div>
                          </div>
                        )}
                        {isImportedAndNotConfirmedState && (
                          <div>
                            <div className="report-overview-metric-title">
                              Your Content Grade
                            </div>
                            <div>
                              Go to the Content Grader and confirm that imported
                              content is correct to reveal grade.
                            </div>
                          </div>
                        )}
                        {!isImportedAndNotConfirmedState && (
                          <div className="mt-1 text-muted text-s">
                            Content Grade shows how well your content covers the
                            topics that it needs to in order to rank for the
                            primary keyword.
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 report-overview-metric-container flex-basis-0">
                      <div className="pr-4 pt-3">
                        <div className="report-overview-metric-title">
                          Keyword Volume
                        </div>
                        <div className="text-xxl text-bold report-overview-card-metric">
                          {_.isEmpty(this.props.report.report.keyword_info) &&
                            "NA"}
                          {!_.isEmpty(
                            this.props.report.report.keyword_info
                          ) && (
                            <div>
                              {formattedNumber(
                                this.props.report.report.keyword_info[0]
                                  .search_volume
                              )}
                              {this.props.report.report.keyword_info[0]
                                .search_volume > 0 && (
                                <a
                                  data-html="true"
                                  data-original-title={`A reasonable number of people are searching for this keyword ${this.props.report.report.keyword_info[0].source == "semrush" ? "<br /> source: <img src='/img/semrush_source_logo.svg' height='23px' />"  : ""}`}
                                  data-toggle="tooltip"
                                  href="#"
                                  title=""
                                  style={{ color: "inherit" }}
                                >
                                  <i className="fas fa-check-circle text-success ml-2" />
                                </a>
                              )}
                              {this.props.report.report.keyword_info[0]
                                .search_volume <= 0 && (
                                <a
                                  data-html="true"
                                  data-original-title={`Very few people are searching for this keyword ${this.props.report.report.keyword_info[0].source == "semrush" ? "<br /> source: <img src='/img/semrush_source_logo.svg' height='23px' />"  : ""}`}
                                  data-toggle="tooltip"
                                  href="#"
                                  title=""
                                  style={{ color: "inherit" }}
                                >
                                  <i className="fas fa-exclamation-triangle text-warning ml-2" />
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="mt-1 text-muted text-s">
                          This is approximately how many searches are made for
                          this keyword every month.
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-grow-1 report-overview-metric-container flex-basis-0">
                      <div className="pr-4 pt-3">
                        <div className="report-overview-metric-title">
                          Articles on First Page
                        </div>
                        <div className="text-xxl text-bold report-overview-card-metric">
                          {!_.isNumber(searchIntentPercent) && "NA"}
                          {_.isNumber(searchIntentPercent) && (
                            <div>
                              {`${searchIntentPercent}%`}
                              {searchIntentPercent >= 50 && (
                                <a
                                  data-original-title="Articles perform well for this keyword"
                                  data-toggle="tooltip"
                                  href="#"
                                  title=""
                                  style={{ color: "inherit" }}
                                >
                                  <i className="fas fa-check-circle text-success ml-2" />
                                </a>
                              )}
                              {searchIntentPercent < 50 && (
                                <a
                                  data-original-title="Articles may not perform well for this keyword"
                                  data-toggle="tooltip"
                                  href="#"
                                  title=""
                                  style={{ color: "inherit" }}
                                >
                                  <i className="fas fa-exclamation-triangle text-warning ml-2" />
                                </a>
                              )}
                            </div>
                          )}
                        </div>
                        <div className="mt-1 text-muted text-s">
                          This is the percent of content on the first page of
                          Google that are articles. The higher the percentage,
                          the better an article will do for this keyword.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="mt-2 w-100 element-box pt-4 pb-4"
                  style={{ position: "relative" }}
                >
                  <h2>Competitive Analysis</h2>
                  <div>
                    <p>
                      Use the tables below to see the competitive landscape for
                      your content when it comes to Domain Authority, Content
                      Grade, and Word Count. Content that beats the average has
                      a higher chance of ranking on the first page.
                    </p>
                      {userIsCafemedia && siteMetrics.isLoading &&
                        <Loader 
                          type="spinner"
                          className="dark"
                          style={{ paddingTop: "0.5rem" }}
                        />}
                      {userIsCafemedia && siteMetrics.domainAuthority && 
                        <a
                          data-html="true"
                          data-original-title={"source: <img src='/img/semrush_source_logo.svg' height='23px' />"}
                          data-toggle="tooltip"
                          href="#"
                          title=""
                          style={{ color: "inherit" }}
                        >
                          <h5 style={{display:'inline-block'}}>Your site's domain authority :  {siteMetrics.domainAuthority && this.fetchCachedDomainAuthority(cafemediaSiteURL)}</h5>
                        </a>}
                  </div>
                  <button
                    className="btn btn-white"
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      opacity: "0.5",
                    }}
                    onClick={this.downloadCompetitors}
                  >
                    <i className="fa fa-download" /> Export CSV
                  </button>
                  {!userIsCafemedia && (
                    <a
                      href="/profile/edit"
                      className="btn btn-white"
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "137px",
                        opacity: "0.5",
                      }}
                    >
                      <i className="fa fa-times-rectangle" /> Ignore Sites
                    </a>
                  )}
                  <ReportComparisonTable
                    report={this.props.report}
                    data={this.props.report.report.competitors}
                    isExpanded={this.state.competitorsExpanded}
                    editorContent={this.props.report.content}
                    isAnon={isAnon}
                  />
                  <div
                    className="clickable bg-bluegray-1 p-3 text-center"
                    onClick={() => {
                      if (isAnon) {
                        $(".upgradeModal").modal("show");
                      } else {
                        this.setState({
                          competitorsExpanded: !this.state.competitorsExpanded,
                        });
                      }
                    }}
                  >
                    {!isAnon && (
                      <span>
                        {this.state.competitorsExpanded
                          ? "Only show first page competitors"
                          : "Show all competitors"}
                      </span>
                    )}
                    {isAnon && (
                      <span>
                        Unlock all competitors <i className="far fa-lock-alt" />
                      </span>
                    )}
                  </div>
                </div>

                <div className="mt-2 w-100 element-box pt-4 pb-4">
                  <ReportOverviewOutlines
                    report={this.props.report}
                    cards={this.props.cards}
                    toggleCard={this.toggleCard}
                    fetchReport={this.props.fetchReport}
                    showToast={this.props.showToast}
                    isAnon={isAnon}
                  />
                </div>

                <div className="mt-2 w-100 element-box pt-4 pb-4">
                  {questionsView == "Pending" && (
                    <div className="position-relative">
                      <h2>Questions To Answer</h2>
                      <p>
                        Looking at commonly searched questions can help you
                        understand what kind of answers your audience is looking
                        for. Find natural ways to answer these questions in your
                        content.
                      </p>
                      <div className="p-2">
                        Fetching Questions To Answer. Please wait...{" "}
                        <Loader
                          type="spinner"
                          className="dark"
                          style={{ paddingTop: "0.5rem" }}
                        />
                        <div className="mt-3">
                          <TextLoader
                            lines={[
                              `Extracting Google people also ask`,
                              "Looking for Google suggestions",
                              "Getting questions from Quora",
                              "Organizing data",
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {(questionsView == "Error" ||
                    questionsView == "Retrying") && (
                    <div className="position-relative">
                      <h2>Questions To Answer</h2>
                      There was an error while fetching "Questions to Answer"{" "}
                      <br />
                      {/* <button className="btn btn-primary right mt-2" type="button" onClick={()=>{this.refreshAsyncComponents('questions')}} disabled={(questionsView == 'Retrying')}>{(questionsView == 'Retrying') ? <p> Retrying .. <Loader type="spinner" style={{paddingTop:'0.5rem'}} /></p> : 'Retry'}</button> */}
                    </div>
                  )}

                  {isNewQuestionView && questionsView == "Success" && (
                    <ReportOverviewQuestions
                      questions={related_questions}
                      quora_questions={related_questions_quora}
                      autocomplete_questions={related_questions_autocomplete}
                      cards={this.props.cards}
                      toggleCard={this.toggleCard}
                      report={this.props.report}
                      showToast={this.props.showToast}
                      fetchReport={this.props.fetchReport}
                      clusteredQuestions={this.props.clusteredQuestions}
                      isAnon={isAnon}
                    />
                  )}
                  {!isNewQuestionView && questionsView == "Success" && (
                    <ReportOverviewQuestionsOld questions={related_questions} />
                  )}
                </div>

                <div
                  className="mt-2 w-100 element-box pt-4 pb-4"
                  style={{ position: "relative" }}
                >
                  <h2>Topics To Cover</h2>
                  <p>
                    Crafting quality content means making sure you cover topics
                    your audience is interested in reading about. Use these key
                    topics to help you create a comprehensive piece that is
                    relevant to your audience. Click on each topic to explore
                    how it is used within other popular content.
                  </p>
                  <ReportOverviewTopics
                    report={this.props.report}
                    highlightedTopics={this.props.highlightedTopics}
                    toggleHighlightedTopic={this.toggleHighlightedTopic}
                    addHighlightedTopics={this.props.addHighlightedTopics}
                    removeHighlightedTopics={this.props.removeHighlightedTopics}
                    ignoreTopic={this.props.ignoreTopic}
                    deleteTopic={this.props.deleteTopic}
                    restoreTopic={this.props.restoreTopic}
                    fetchReport={this.props.fetchReport}
                    isAnon={isAnon}
                    setGroupBySemanticSimiliarity={
                      this.setGroupBySemanticSimiliarity
                    }
                    groupBySemanticSimilarity={groupBySemanticSimilarity}
                    showToast={this.props.showToast}
                  />
                </div>

                <div
                  className="mt-2 w-100 element-box pt-4 pb-4"
                  style={{ position: "relative" }}
                >
                  <h2>Statistics</h2>
                  <div>
                    <p>
                      Citing original research establishes trust with your
                      readers. Use this tool to find relevant statistics for
                      your brief.
                    </p>
                  </div>
                  <ReportStatisticsTable
                    focusKeyword={this.props.report.report.focus_keyword}
                    competitorData={this.props.report.report.competitors}
                    toggleHighlightedStatistic={this.toggleHighlightedStatistic}
                    highlightedStatistics={_.filter(
                      this.props.cards,
                      (c) => c.identifier == "statistics"
                    )}
                  />
                </div>

                <div className="mt-2 w-100 element-box pt-4 pb-4">
                  {/* <div className="col-md-3 element-box mb-0">
                    <h2>Related Searches</h2>
                    <div>
                      <p>These are the keywords that Google suggests as follow-up searches</p>
                    </div>
                    {_.isEmpty(this.props.report.report.related_searches) && <div className="p-2">No related searches found</div>}
                    {!_.isEmpty(this.props.report.report.related_searches) && 
                      <div className="d-flex flex-column">
                        {_.map(this.props.report.report.related_searches, (search, index) => {return <a href={`https://www.google.com/search?q=${encodeURIComponent(search)}`} target="_blank" key={index} >
                            <div className="hoverable clickable text-bold p-3">
                              {search}
                            </div>
                          </a>
                        })}
                      </div>
                    }
                  </div> */}

                  <h2>Commonly Cited Links</h2>
                  <div>
                    <p>
                      These are the links that competitors most commonly cite.
                      Improve your content's trustworthiness to readers by
                      incorporating them into your content also.
                    </p>
                  </div>
                  <ReportCommonlyCitedLinksTable
                    data={this.props.report.report.external_links_analysis}
                    toggleHighlightedCitations={this.toggleHighlightedCitations}
                    highlightedCitations={_.filter(
                      this.props.cards,
                      (c) => c.identifier == "citations"
                    )}
                    CitationsStatus={citationsView}
                    refreshAsyncComponents={this.refreshAsyncComponents}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {!outlineBuilderMinimized && (
          <div className="report-overview-builder-holder flex-grow-1">
            <div className="ml-3">
              <div
                className={`nav-side-video flex-grow-1 ${
                  isEditor ? "d-none" : ""
                }`}
              >
                {/* <div className="text-right" style={{paddingTop: '15px', paddingRight: '40px',display:'none'}}>
                    <div className="pr-2 d-inline clickable" onClick={this.showVideoPopup} >How To Build an Outline with Topic</div>
                    <img className="clickable topic-video-preview" src="/img/video-preview.png" onClick={this.showVideoPopup} width="60px" />
                  </div> */}
              </div>
              <OutlineBuilder
                report={this.props.report}
                minScreenWidth={this.outlineBuilderMinScreenWidth}
                onMinimize={this.handleOutlineBuilderMinimize}
                groupBySemanticSimilarity={groupBySemanticSimilarity}
              />
              <div className="w-100 builder-width-reference" />
            </div>
          </div>
        )}
        {outlineBuilderMinimized && (
          <div
            className="clickable builder-expand"
            onClick={this.handleOutlineBuilderExpand}
          >
            <i className="fas fa-chevron-right" /> Build Outline
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    report: getReportById(state, ownProps.match.params.id),
    isFetching: state.reports.isFetching,
    cards: state.outline.cards,
    highlightedTopics: state.outline.highlightedTopics,
    outline: state.outline,
    clusteredQuestions: getClusteredQuestions(state, ownProps.match.params.id),
  };
}

export default withRouter(
  connect(mapStateToProps, {
    fetchReportIfNeeded,
    fetchReport,
    ignoreTopic,
    restoreTopic,
    toggleCard,
    toggleHighlightedTopic,
    toggleHighlightedCitations,
    toggleHighlightedStatistic,
    removeHighlightedTopics,
    addHighlightedTopics,
    showToast,
    deleteTopic,
    requestContentRevisions,
  })(ReportOverview)
);
